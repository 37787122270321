import {createReducer} from '@reduxjs/toolkit'
import {CHANGE_EVENT} from '../actions/event'
import {selectTicket, unselectTicket, selectTickets} from '../actions/selected-tickets'
import {SelectedTickets} from '../types'

const defaultState: SelectedTickets = {}

export const selectedTickets = createReducer(defaultState, builder => {
  builder
    .addCase(selectTicket, (state, {payload: {ticketId, count, seatId}}) => {
      const currentSeatIds = state[ticketId]?.seatIds ?? []

      return {
        ...state,
        [ticketId]: {
          quantity: count,
          seatIds: seatId ? [...currentSeatIds, seatId] : currentSeatIds,
        },
      }
    })
    .addCase(selectTickets, (state, {payload}) => ({...state, ...payload}))
    .addCase(unselectTicket, (state, {payload: {ticketId, count, seatId}}) => ({
      ...state,
      [ticketId]: {
        quantity: count,
        seatIds: state[ticketId].seatIds.filter(seat => seat !== seatId),
      },
    }))
    .addCase(CHANGE_EVENT, () => defaultState)
})
